// const config = {
//   graphQLUri: 'http://localhost:4000/graphql',
//   oauthUrl: 'http://localhost:4001/oauth/authorize',
//   logoutUrl: 'http://localhost:4001/logout?redirectTo=http://localhost:3001',
//   client_id: 'noise_studio_clientId',
//   redirect_uri: 'http://localhost:3000/login',
//   edit_profile_uri: 'http://localhost:4001/profile',
//   languages: ['it', 'en'],
//   defaultLanguage: 'it',

//   baseUrl: 'http://localhost:4000',
// };

const config = {
  graphQLUri: 'http://localhost:4200/graphql',
  oauthUrl: 'http://localhost:4000/oauth/authorize',
  logoutUrl: 'http://localhost:4000/logout?redirectTo=http://localhost:4200',
  client_id: 'noise_studio_clientId',
  redirect_uri: 'http://localhost:3001/login',
  edit_profile_uri: 'http://localhost:4000/profile',
  languages: ['it', 'en'],
  defaultLanguage: 'it',

  baseUrl: 'http://localhost:4200',
};

// const config = {
//   graphQLUri: 'https://s-api-noise-studio-deltaohm.geekcups.com/graphql',
//   oauthUrl: 'https://s-login-deltaohm.geekcups.com/oauth/authorize',
//   logoutUrl: 'https://s-login-deltaohm.geekcups.com/logout?redirectTo=https://s-noise-studio-deltaohm.geekcups.com',
//   client_id: 'c3k4m5p7q8r9t2j3k4n6',
//   redirect_uri: 'https://s-noise-studio-deltaohm.geekcups.com/login',
//   edit_profile_uri: 'https://s-login-deltaohm.geekcups.com/profile',
//   languages: ['it', 'en'],
//   defaultLanguage: 'en',
//   baseUrl: 'https://s-api-noise-studio-deltaohm.geekcups.com',
// };

// const config = {
//   graphQLUri: 'https://graphql-noise-studio.senseca.com/graphql',
//   oauthUrl: 'https://login-noise-studio.senseca.com/oauth/authorize',
//   logoutUrl: 'https://login-noise-studio.senseca.com/logout?redirectTo=https://noise-studio.senseca.com',
//   client_id: 'whhrhhremh5qdi8urfn4',
//   redirect_uri: 'https://noise-studio.senseca.com/login',
//   edit_profile_uri: 'https://login-noise-studio.senseca.com/profile',
//   languages: ['it', 'en'],
//   defaultLanguage: 'it',
//   baseUrl: 'https://graphql-noise-studio.senseca.com',
// };

export default config;
